import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
// import Row from './../../common/components/Row';
// import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
// import Cta from './../../common/components/Cta';

// import {LAYOUT_SM, LAYOUT_XS } from '../../config';


const Associazione = ({layout}) => {

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Associazione"}
        languages={{"it": "associazione"}}
      />
      <Header 
        uppertext={"Associazione"}
        text={<>Proteggiamo, riabilitiamo, diamo una seconda possibilità.</>}
        lowertext={<>Assieme possiamo fare la differenza nelle loro vite.</>}
        page="associazione"
        alt="Emotional image"
      />

      <Section 
        variant="primary-3" 
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2">Chi siamo</Typo>
          <Typo type="p" align="left">
            L'Associazione Equinozio ODV nasce dal desiderio di assicurare un futuro a tutti quei cavalli o equidi in generale che, per i più svariati motivi, si trovano in pericolo o senza una famiglia.<br/>Uno dei nostri obiettivi principali è quello di diffondere il concetto di cavallo come AMICO e compagno di vita e non come strumento utile a raggiungere i nostri scopi personali.  
          </Typo>
          <Typo type="p" align="left">
            Molto spesso il cavallo viene considerato come oggetto e quando "si deteriora" viene letteralmente scartato e la sua vita cambia radicalmente passando dalle lezioni o dalle passeggiate in compagnia ad una deprimente solitudine... Così i cavalli si lasciano andare, diventano apatici e piano piano si spengono.  
          </Typo>
          <Typo type="p" align="left">
            L'associazione quindi si propone di aiutare cavalli maltrattati o che il proprietario non può più mantenere, cavalli destinati al macello, o all'eutanasia in quanto ormai anziani, o seppur giovani con problematiche fisiche e psicologiche.  
          </Typo>  
        </Padding>
      </Section>

      <Section 
        variant="primary-2" 
        imageDx={"/web/sections/associazione-cosafacciamo.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2">Che cosa facciamo?</Typo>
          <Typo type="p" align="left">
            <ul>
              <li>Salviamo equidi e li aiutiamo  sia dal punto di vista fisico che da quello psicologico.</li>
              <li>Troviamo famiglie nuove per i cavalli salvati.</li>
              <li>Siamo di supporto a chi si impegna ad aiutare cavalli bisognosi.</li>
              <li>Organizziamo raccolte fondi, banchetti e cene per sostenere le spese del mantenimento, dei veterinari, dei medicinali e integratori dei nostri e altri cavalli.</li>
              <li>Organizziamo corsi e giornate a tema per far conoscere il mondo del cavallo.</li>
            </ul>
          </Typo>  
        </Padding>
      </Section>
    </Layout>
  )
}

export default Associazione;
