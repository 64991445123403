import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';
// import StorageService from './../../services/StorageService';
import DatabaseService from './../../services/DatabaseService';
// import StripeService from './../../services/StripeService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
import Image from './../../common/components/Image';             
import Loading from './../../common/components/Loading';
// import Cta from '../../common/components/Cta';
// import Circle from '../../common/components/Circle';


import NotFound from './NotFound';

const Label = styled.div`
  font-family: 'Skranji', cursive;
  font-size: 18px;
  font-weight: bold;
`;
const Value = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;



const CavalliSingle = ({layout}) => {
  let { slug } = useParams();
  const [horse, setHorse] = useState(null);
  // const [circle, setCircle] = useState(null);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);
  
  
  useEffect(() => {
    DatabaseService.getHorse(slug).then((response) => {
      if(response.success){
        setHorse(response.data);
        // if(response.data.stripeCircleId){
        //   StripeService.listSubscriptionsByHorse(response.data.stripeCircleId).then((response2) => {
        //     setCircle(response2);
        //   });   
        // }
      }else{
        console.log(response.error);
      }
    });    
  }, [slug]);

  if(horse === null){
    return (
      <Loading />
    )
  }else if(typeof horse === 'undefined'){
    return (
      <NotFound />
    )
  }else{
    return (
      <Layout>
        <Meta
          title={horse.emergency ? "Aiutaci a salvare " + horse.name : horse.name}
          description={horse.short}
          image={"horses/" + horse.slug + "-zoom.png"}
          languages={{"it": "cavalli/"+horse.name}}
        />
        <Section variant="primary-3">
          <Padding top right bottom left>
       
            <Typo type="h1" align="center">{horse.name}</Typo>
            <Row>
              <Col widthmd={8}>
                <Image src={"horses/" + horse.slug + "-zoom.png"} alt={horse.name + " di Equinozio ODV"} />
              </Col>
              <Col widthmd={4}>
                {horse.name !== horse.docname ?
                  <>
                    <Label>Nome originale</Label>
                    <Value>{horse.docname}</Value>
                  </>
                : "" }
                {horse.sex ?
                  <>
                    <Label>Sesso</Label>
                    <Value>{horse.sex}</Value>
                  </>
                : "" }
                {horse.birthday ?
                  <>
                    <Label>Data di nascita</Label>
                    <Value>{horse.birthday}</Value>
                  </>
                : "" }
                {horse.birthplace ?
                  <>
                    <Label>Luogo di nascita</Label>
                    <Value>{horse.birthplace}</Value>
                  </>
                : "" }
                {horse.location ?
                  <>
                    <Label>Location</Label>
                    <Value>{horse.location}</Value>
                  </>
                : "" }
                {/*horse.status ?
                  <>
                    <Label>Stato</Label>
                    <Value>{translations["status"][horse.status][lang]}</Value>
                    {horse.status === "In cerca di casa" ?
                      <Cta 
                        variant="light-o"
                        text={"Candidati per l'adozione"}
                        link={"/sostienici/candidatura-adozione"}
                        icon="arrow_forward"
                      />
                    : null }
                  </>
                : "" */}
                {/*{horse.circle && horse.circle.length > 0 ?
                  <>
                    <Label>La sua cerchia</Label>
                    <Value>{horse.circle.map(person => person.nickname).join(", ")}</Value>
                  </>
                : "" }*/}
                
                
              </Col>
            </Row>
        </Padding>
      </Section>
      <Section variant="primary-2">
        <Padding top right bottom left>
          {horse["claim_it"] ?
            <Typo type="h4" align="center">{horse["claim_it"]}</Typo>
          : "" }
          {horse["description_it"] ?
            <Typo type="p" align="center"><div dangerouslySetInnerHTML={{__html: horse["description_it"]}} /></Typo>
          : "" }
        </Padding>
      </Section>

        {/* { false ?
        <>
        {horse.stripeCircleLink && horse.stripeCircleId ?
          <Section variant="wheat">
            <Typo type="h2" align="center">{lang === "it" ? "La Cerchia di " + horse.name : horse.name + "'s Circle" }</Typo>
            <Row>
              <Col sm={6}>
                <Circle 
                  oldlist={horse.circle}
                  list={circle}
                  horse={horse}
                />
              </Col>
              <Col sm={12}>
                <Typo type="p" align="center">
                  {circle && circle.length > 0 ? 
                    circle.map(person => person.nickname + " ("+person.quantity+")").join(", ") 
                  : 
                    (lang === "it" ? "Non c'è ancora nessuno qui!" : "No one is here yet!")
                  }
                </Typo>
                <Typo type="p" align="center">

                  <a href={horse.stripeCircleLink} target="_blank" rel="noreferrer">
                    <Button size="big" variant="mint">{lang === "it" ? "Entra nella cerchia" : "Enter the Circle"}</Button>
                  </a>
                  {/ * o gestisci qui i tuoi abbonamenti: <a href={"https://billing.stripe.com/p/login/00gaFfgIV8TK8hi000"} rel="noreferrer" target="_blank">Pagina utente</a> * /}
                </Typo>
              </Col>
            </Row>
          </Section>
        : null }
        </>
        : null }
        
        {horse.stripeDonationLink?
          <Section variant="wheat">
            {horse.emergency ?
              <Typo type="h2" align="center">{(lang === "it" ? "Aiutaci a salvare " : "Help us save ") + horse.name}</Typo>
            :
              <Typo type="h2" align="center">{(lang === "it" ? "Sostieni " : "Support ") + horse.name}</Typo>
            }
            <Row>
              <Col sm={horse.donationImageSlug ? 6 : 12}>
                    
                {horse["donationDescription_"+lang] ?
                  <Typo type="p" align="center"><div dangerouslySetInnerHTML={{__html: horse["donationDescription_"+lang]}} /></Typo>
                : horse.emergency ?
                  <>
                    {lang === "it" ? 
                      <>
                        <Typo type="p" align="center">
                          Stiamo sostenendo molte spese per {horse.name}, aiutaci ad affrontarle!
                        </Typo>
                      </>
                    : 
                      <>
                        <Typo type="p" align="center">
                          We are incurring a lot of expenses for {horse.name}, help us cope!
                        </Typo>
                      </>
                    }
                  </>  
                :
                  <>
                    {lang === "it" ? 
                      <>
                        <Typo type="p" align="center">
                          Vuoi darci un piccolo aiuto per {horse.name}? Puoi farlo anche con una donazione singola.
                        </Typo>
                      </>
                    : 
                      <>
                        <Typo type="p" align="center">
                          Would you like to give us a little help for {horse.name}? You can also do so with a single donation.
                        </Typo>
                      </>
                    }
                  </>
                }
                <br/>
                <center>
                  <a href={horse.stripeDonationLink} target="_blank" rel="noreferrer">
                    <Button size="big" variant="peach">{lang === "it" ? "Dona ora" : "Donate now"}</Button>
                  </a>
                </center>   
                <br/>
                <center>{lang === "it" ? "oppure" : "otherwise"}</center>
                <br/>
        
                <Typo type="p">
                  <b>{lang === "it" ? "Bonifico Bancario" : "Bank Transfer"}</b>
                  <br/>IBAN: IT74S0103058030000010190156  
                  <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Associazione Equinozio Onlus
                  <br/>{lang === "it" ? "Causale" : "Description"}: {horse.donationReason}
                  <br/><small>{lang === "it" ? "Ai fini di un rapido aggiornamento dello stato della raccolta, segnalare la donazione via mail o social." : "For the purpose of a quick update on the status of the collection, report the donation via email or social media."}</small>
                </Typo>
                
                <Typo type="p">
                  <b>PayPal</b>
                  <br/>Email: equinozioonlus@gmail.com  
                  <br/>{lang === "it" ? "Causale" : "Description"}: {horse.donationReason}
                </Typo>
                
                <Typo type="p">
                  <b>Ricarica PostePay</b>
                  <br/>N°: 4023 6009 3490 3560  
                  <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Erica Coizzi ({lang === "it" ? "presidente" : "president"})
                  <br/>{lang === "it" ? "Codice fiscale" : "Tax code"}: CZZRCE79C60E897K 
                  <br/><small>{lang === "it" ? "Ai fini di un rapido aggiornamento dello stato della raccolta, segnalare la donazione via mail o social." : "For the purpose of a quick update on the status of the collection, report the donation via email or social media."}</small>
                </Typo>
            
                <br/><br/>
              </Col>
              {horse.donationImageSlug ?
                <Col sm="6">
                  <center>
                    <Image src={"campaigns/" + horse.donationImageSlug + "_"+lang+".png"} alt={(lang === "it" ? "Aiutaci a salvare " : "Help us save ") + horse.name} />
                  </center>
                </Col>
              : null }
            </Row>
                     
          </Section>
        : null } */}

      </Layout>
    )
  }
}

export default CavalliSingle;
