import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

import Image from './../../common/components/Image';


const Wrapper = styled(Link)`
  position: relative;
  display: block;
`;
// const Image = styled.img`
//   width: 100%;
// `;
const Overlay = styled.div`
  opacity: 0;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  background: #05383999;

  &:hover{
    opacity: 1;
  }

  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

`;
const Typo = styled.div`
  font-family: 'Skranji', cursive;
  font-size: 30px;
  color: #ffffff;
`;


const HorseThumb = ({ item }) => {

  if(item){
    return (
      <Wrapper to={"/cavalli/" + item.slug}>
        
        <Image src={"horses/" + item.slug + "-thumb.png"} alt={item.name + " di Equinozio ODV"} />
        <Overlay>
          <Typo>{item.name}</Typo>
        </Overlay>
      </Wrapper>
    )
    }else{
      return <></>;
    }
}
export default HorseThumb;
