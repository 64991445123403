import {useState, useCallback, useEffect, useContext} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {UserContext} from './contexts/UserContext';
// import { getAnalytics } from "firebase/analytics";

//Frontend
import Home from "./frontend/pages/Home";
import Associazione from "./frontend/pages/Associazione";
import Sostienici from "./frontend/pages/Sostienici";
import SostieniciCinquepermille from "./frontend/pages/SostieniciCinquepermille";
// import SostieniciVolontariatoadistanza from "./frontend/pages/SostieniciVolontariatoadistanza";
// import SostieniciCandidaturaAdozione from "./frontend/pages/SostieniciCandidaturaAdozione";
import CavalliList from "./frontend/pages/CavalliList";
import CavalliSingle from "./frontend/pages/CavalliSingle";
// import EventiList from "./frontend/pages/EventiList";
// import EventiSingle from "./frontend/pages/EventiSingle";
import Contatti from "./frontend/pages/Contatti";
// import ContattiRichiestaCessione from "./frontend/pages/ContattiRichiestaCessione";



// import BlogList from "./frontend/pages/BlogList";
// import BlogSingle from "./frontend/pages/BlogSingle";
import CircleApplication from "./frontend/pages/CircleApplication";

// import CinquePerMille from "./frontend/pages/CinquePerMille";

//Authentication
// import Registration from "./backend/pages/Registration";
import Login from "./backend/pages/Login";

//Backend
import AdminDashboard from './backend/pages/Dashboard';
import AdminHorsesList from './backend/pages/HorsesList';
import AdminHorsesSingle from './backend/pages/HorsesSingle';
import AdminEventsList from './backend/pages/EventsList';
import AdminEventsSingle from './backend/pages/EventsSingle';
import AdminAdoptionApplicationsList from './backend/pages/AdoptionApplicationsList';
import AdminAdoptionApplicationsSingle from './backend/pages/AdoptionApplicationsSingle';
import AdminCessionApplicationsList from './backend/pages/CessionApplicationsList';
import AdminCessionApplicationsSingle from './backend/pages/CessionApplicationsSingle';

//404
import NotFound from "./frontend/pages/NotFound";

import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';

const RequireAuth = ({children}: {children: JSX.Element}) => {
  const {user} = useContext(UserContext);
  let location = useLocation();
  // console.log(user);
  if (!user) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }
  return (<>{children}</>);
}

const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  const [layout, setLayout] = useState(null);

  // useEffect(() => {
  //   AnalyticsService.getUrl(src).then((response) => {
  //     if(response.success){
  //       setUrl(response.url);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, []);

  const handleResize = useCallback(() => {
    setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  return (
    <Routes>

      {/*301*/}
      {/* <Route exact path='/' element={<Navigate to={"/it"} />} /> */}

      {/*Frontend*/}
      <Route exact path='/' element={<Home layout={layout} />} />
      <Route exact path='/associazione' element={<Associazione layout={layout} />} />
      <Route exact path='/sostienici' element={<Sostienici layout={layout} />} />
      <Route exact path='/sostienici/cerchie' element={<CircleApplication layout={layout} />} />
      <Route exact path='/sostienici/cinque-x-mille' element={<SostieniciCinquepermille layout={layout} />} />
      <Route exact path='/cavalli' element={<CavalliList layout={layout} />} />
      <Route exact path='/cavalli/:slug' element={<CavalliSingle layout={layout} />} />
      <Route exact path='/contatti' element={<Contatti layout={layout} />} />
      
      {/* <Route exact path='/contatti/richiesta-cessione' element={<ContattiRichiestaCessione layout={layout} />} />
      <Route exact path='/contacts/cession-request' element={<ContattiRichiestaCessione layout={layout} />} /> */}

      {/*
      <Route exact path='/sostienici/volontariato-a-distanza' element={<SostieniciVolontariatoadistanza layout={layout} />} />
      <Route exact path='/sostienici/candidatura-adozione' element={<SostieniciCandidaturaAdozione layout={layout} />} />
      <Route exact path='/eventi' element={<EventiList layout={layout} />} />
      <Route exact path='/eventi/:slug' element={<EventiSingle layout={layout} />} />
      <Route exact path='/:lang/blog' element={<BlogList layout={layout} />} />
      <Route exact path='/:lang/blog/:slug' element={<BlogSingle layout={layout} />} />
      */}


        
      {/*Redirect*/}    
      <Route exact path='/:lang/' element={<Navigate to={"/"} />} />
      <Route exact path='/:lang/chi-siamo' element={<Navigate to={"/associazione"} />} />
      <Route exact path='/:lang/about-us' element={<Navigate to={"/associazione"} />} />
      <Route exact path='/:lang/associazione' element={<Navigate to={"/associazione"} />} />
      <Route exact path='/:lang/association' element={<Navigate to={"/associazione"} />} />   
      <Route exact path='/:lang/come-aiutarci' element={<Navigate to={"/sostienici"} />} />   
      <Route exact path='/:lang/sostienici' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/help-us' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/support-us' element={<Navigate to={"/sostienici"} />} />  
      <Route exact path='/:lang/sostienici/cerchie' element={<Navigate to={"/sostienici/cerchie"} />} />
      <Route exact path='/:lang/support-us/distance-adoptions' element={<Navigate to={"/sostienici/cerchie"} />} />
      <Route exact path='/:lang/come-aiutarci/cerchie' element={<Navigate to={"/sostienici/cerchie"} />} />
      <Route exact path='/:lang/help-us/cerchie' element={<Navigate to={"/sostienici/cerchie"} />} />
      <Route exact path='/:lang/sostienici/cinquexmille' element={<Navigate to={"/sostienici/cinque-x-mille"} />} />
      <Route exact path='/:lang/support-us/cinquexmille' element={<Navigate to={"/sostienici/cinque-x-mille"} />} />
      <Route exact path='/:lang/sostienici/cinque-x-mille' element={<Navigate to={"/sostienici/cinque-x-mille"} />} />
      <Route exact path='/:lang/support-us/cinque-x-mille' element={<Navigate to={"/sostienici/cinque-x-mille"} />} />
      <Route exact path='/:lang/sostienici/volontariatoadistanza' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/support-us/remotevolunteering' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/sostienici/volontariato-a-distanza' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/support-us/remote-volunteering' element={<Navigate to={"/sostienici"} />} />
      <Route exact path='/:lang/sostienici/candidatura-adozione' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/support-us/adoption-application' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/cavalli' element={<Navigate to={"/cavalli"} />} />
      <Route exact path='/:lang/horses' element={<Navigate to={"/cavalli"} />} /> 
      
      <Route exact path='/:lang/cavalli/ada' element={<Navigate to={"/cavalli/ada"} />} />
      <Route exact path='/:lang/horses/ada' element={<Navigate to={"/cavalli/ada"} />} /> 
      <Route exact path='/:lang/cavalli/alba' element={<Navigate to={"/cavalli/alba"} />} />
      <Route exact path='/:lang/horses/alba' element={<Navigate to={"/cavalli/alba"} />} /> 
      <Route exact path='/:lang/cavalli/aramisz' element={<Navigate to={"/cavalli/aramisz"} />} />
      <Route exact path='/:lang/horses/aramisz' element={<Navigate to={"/cavalli/aramisz"} />} /> 
      <Route exact path='/:lang/cavalli/aylen' element={<Navigate to={"/cavalli/aylen"} />} />
      <Route exact path='/:lang/horses/aylen' element={<Navigate to={"/cavalli/aylen"} />} /> 
      <Route exact path='/:lang/cavalli/benji' element={<Navigate to={"/cavalli/benji"} />} />
      <Route exact path='/:lang/horses/benji' element={<Navigate to={"/cavalli/benji"} />} /> 
      <Route exact path='/:lang/cavalli/blu' element={<Navigate to={"/cavalli/blu"} />} />
      <Route exact path='/:lang/horses/blu' element={<Navigate to={"/cavalli/blu"} />} /> 
      <Route exact path='/:lang/cavalli/colorado' element={<Navigate to={"/cavalli/colorado"} />} />
      <Route exact path='/:lang/horses/colorado' element={<Navigate to={"/cavalli/colorado"} />} /> 
      <Route exact path='/:lang/cavalli/dacki' element={<Navigate to={"/cavalli/dacki"} />} />
      <Route exact path='/:lang/horses/dacki' element={<Navigate to={"/cavalli/dacki"} />} /> 
      <Route exact path='/:lang/cavalli/dudu' element={<Navigate to={"/cavalli/dudu"} />} />
      <Route exact path='/:lang/horses/dudu' element={<Navigate to={"/cavalli/dudu"} />} /> 
      <Route exact path='/:lang/cavalli/esperanza' element={<Navigate to={"/cavalli/esperanza"} />} />
      <Route exact path='/:lang/horses/esperanza' element={<Navigate to={"/cavalli/esperanza"} />} /> 
      <Route exact path='/:lang/cavalli/frodo' element={<Navigate to={"/cavalli/frodo"} />} />
      <Route exact path='/:lang/horses/frodo' element={<Navigate to={"/cavalli/frodo"} />} /> 
      <Route exact path='/:lang/cavalli/kiya' element={<Navigate to={"/cavalli/kiya"} />} />
      <Route exact path='/:lang/horses/kiya' element={<Navigate to={"/cavalli/kiya"} />} /> 
      <Route exact path='/:lang/cavalli/maverick' element={<Navigate to={"/cavalli/maverick"} />} />
      <Route exact path='/:lang/horses/maverick' element={<Navigate to={"/cavalli/maverick"} />} /> 
      <Route exact path='/:lang/cavalli/roky' element={<Navigate to={"/cavalli/roky"} />} />
      <Route exact path='/:lang/horses/roky' element={<Navigate to={"/cavalli/roky"} />} /> 
      <Route exact path='/:lang/cavalli/sad' element={<Navigate to={"/cavalli/sad"} />} />
      <Route exact path='/:lang/horses/sad' element={<Navigate to={"/cavalli/sad"} />} /> 
      <Route exact path='/:lang/cavalli/shasir' element={<Navigate to={"/cavalli/shasir"} />} />
      <Route exact path='/:lang/horses/shasir' element={<Navigate to={"/cavalli/shasir"} />} /> 
      <Route exact path='/:lang/cavalli/margarita' element={<Navigate to={"/cavalli/margarita"} />} />
      <Route exact path='/:lang/horses/margarita' element={<Navigate to={"/cavalli/margarita"} />} /> 
      <Route exact path='/:lang/cavalli/sugar-smart-spot' element={<Navigate to={"/cavalli/sugar-smart-spot"} />} />
      <Route exact path='/:lang/horses/sugar-smart-spot' element={<Navigate to={"/cavalli/sugar-smart-spot"} />} /> 
      <Route exact path='/:lang/cavalli/curry-king' element={<Navigate to={"/cavalli/curry-king"} />} />
      <Route exact path='/:lang/horses/curry-king' element={<Navigate to={"/cavalli/curry-king"} />} /> 
      <Route exact path='/:lang/cavalli/gerba' element={<Navigate to={"/cavalli/gerba"} />} />
      <Route exact path='/:lang/horses/gerba' element={<Navigate to={"/cavalli/gerba"} />} /> 
      <Route exact path='/:lang/cavalli/gino' element={<Navigate to={"/cavalli/gino"} />} />
      <Route exact path='/:lang/horses/gino' element={<Navigate to={"/cavalli/gino"} />} /> 
      <Route exact path='/:lang/cavalli/kristal' element={<Navigate to={"/cavalli/kristal"} />} />
      <Route exact path='/:lang/horses/kristal' element={<Navigate to={"/cavalli/kristal"} />} /> 
      <Route exact path='/:lang/cavalli/leila' element={<Navigate to={"/cavalli/leila"} />} />
      <Route exact path='/:lang/horses/leila' element={<Navigate to={"/cavalli/leila"} />} /> 
      <Route exact path='/:lang/cavalli/rio' element={<Navigate to={"/cavalli/rio"} />} />
      <Route exact path='/:lang/horses/rio' element={<Navigate to={"/cavalli/rio"} />} /> 
      <Route exact path='/:lang/cavalli/tom' element={<Navigate to={"/cavalli/tom"} />} />
      <Route exact path='/:lang/horses/tom' element={<Navigate to={"/cavalli/tom"} />} /> 

      <Route exact path='/:lang/contatti' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/contacts' element={<Navigate to={"/contatti"} />} />   
      <Route exact path='/:lang/candidatura-cessione' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/cession-application' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/candidatura-adozione' element={<Navigate to={"/contatti"} />} />
      <Route exact path='/:lang/adoption-application' element={<Navigate to={"/contatti"} />} />
      

      {/*Authentication*/}
      {/*<Route exact path='/admin/registration' element={<Registration />} />*/}
      <Route exact path='/admin/login' element={<Login />} />

      {/*Backend*/}
      <Route exact path='/admin' element={<RequireAuth><AdminDashboard layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/horses' element={<RequireAuth><AdminHorsesList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/horses/:slug" element={<RequireAuth><AdminHorsesSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/events' element={<RequireAuth><AdminEventsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/events/:slug" element={<RequireAuth><AdminEventsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/adoption-applications' element={<RequireAuth><AdminAdoptionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/adoption-applications/:slug" element={<RequireAuth><AdminAdoptionApplicationsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/cession-applications' element={<RequireAuth><AdminCessionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/cession-applications/:slug" element={<RequireAuth><AdminCessionApplicationsSingle layout={layout} /></RequireAuth>} />

      {/*404*/}
      <Route path='*' element={<NotFound layout={layout} />} />
    </Routes>
  );
}

export default App;
