import React, {useState, useEffect} from 'react';

import AnalyticsService from './../../services/AnalyticsService';
import DatabaseService from './../../services/DatabaseService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Showcase from '../layout/Showcase';
import Typo from './../../common/components/Typo';

import {LAYOUT_SM, LAYOUT_XS } from '../../config';

const CavalliList = ({layout}) => {

  const [horsesSearching, setHorsesSearching] = useState([]);
  const [horsesResident, setHorsesResident] = useState([]);
  const [horsesAdopted, setHorsesAdopted] = useState([]);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);
  
  useEffect(() => {
    DatabaseService.getHorses({
      status: "In cerca di casa", 
      showWeb: true,
      order: "name"
    }).then((response) => {
      if(response.success){
        setHorsesSearching(response.data);
      }else{
        console.log(response.error);
      }
    });
    DatabaseService.getHorses({
      status: "Residente dell'Oasi", 
      showWeb: true,
      order: "name"
    }).then((response) => {
      if(response.success){
        setHorsesResident(response.data);
      }else{
        console.log(response.error);
      }
    });
    DatabaseService.getHorses({
      status: "Adottato", 
      showWeb: true,
      order: "name"
    }).then((response) => {
      if(response.success){
        setHorsesAdopted(response.data);
      }else{
        console.log(response.error);
      }
    });

    
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Cavalli"}
        languages={{"it": "cavalli"}}
      />
      <Section variant="secondary-2">
        <Padding top right bottom left>
          {horsesSearching && horsesSearching.length > 0 ? 
            <>
              <Typo type="h2" align="center">Cavalli in cerca di casa</Typo>
              <Typo type="p" align="center">I cavalli in cerca di casa hanno bisogno di una famiglia che possa adottarli.<br/>&Egrave; possibile sostenerli finché non trovano casa.</Typo>
            
              
              <Showcase
                list={horsesSearching}
                cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
              />
            </>
          : null }
        </Padding>
      </Section>
      <Section variant="primary-3">
        <Padding top right bottom left>
          <>
            <Typo type="h2" align="center">Cavalli residenti dell'Oasi</Typo>
            <Typo type="p" align="center">I cavalli residenti dell'Oasi vivono nel nostro branco.<br/>&Egrave; possibile prenderli in fida, mezzafida, o adottarli a distanza.</Typo>
              
            <Showcase
              list={horsesResident}
              cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
            /> 
          </>
        </Padding>
      </Section>
      <Section variant="secondary-1">
        <Padding top right bottom left>
          <>
          
            <Typo type="h2" align="center">Cavalli adottati</Typo>
            <Typo type="p" align="center">I cavalli adottati vivono felicemente con la loro nuova famiglia.</Typo>
              

            <Showcase
              list={horsesAdopted}
              cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
            />
          </>
        </Padding>
      </Section>
    </Layout>
  )
}

export default CavalliList
